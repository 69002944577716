// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customVotesCell {
    border: 1px solid rgb(213, 211, 211);
    border-radius: 8px !important;
    height: 31px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto !important;
    max-width: 200px;
  }

  .customOtherVotesCell {
    border: none !important;
  }

  .customPartyCell {
    /* color: #2AACE2; */
    color: 'text.secondary';
    font-weight: 400;
    font-size: 16px;
  }

  .OtherVotesPartyCell {
    color: 'text.secondary';
    font-weight: 400;
    font-size: 16px;
  }
`, "",{"version":3,"sources":["webpack://./src/components/style.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,6BAA6B;IAC7B,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;EAClB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,oBAAoB;IACpB,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;EACjB","sourcesContent":[".customVotesCell {\n    border: 1px solid rgb(213, 211, 211);\n    border-radius: 8px !important;\n    height: 31px !important;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: auto !important;\n    max-width: 200px;\n  }\n\n  .customOtherVotesCell {\n    border: none !important;\n  }\n\n  .customPartyCell {\n    /* color: #2AACE2; */\n    color: 'text.secondary';\n    font-weight: 400;\n    font-size: 16px;\n  }\n\n  .OtherVotesPartyCell {\n    color: 'text.secondary';\n    font-weight: 400;\n    font-size: 16px;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

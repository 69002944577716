import * as React from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import ColorModeIconDropdown from "../theme/share/ColorModeIconDropdown";

export default function Header() {
  return (
    <Stack
      direction="row"
      sx={{
        display: { xs: "none", md: "flex" },
        width: "100%",
        alignItems: { xs: "flex-start", md: "center" },
        justifyContent: "space-between",
        maxWidth: { sm: "100%", md: "1700px" },
        pt: 1,
      }}
      spacing={2}
    >
      <Typography
        sx={{
          padding: "5px ",
          width: "100%",
          margin: "auto",
          fontFamily: "Outfit",
          fontWeight: "500",
          color: "text.primary",
          fontSize: "24px",
        }}
      >
        Election Calculator: Parliament Election 2024 - Sri Lanka
      </Typography>
      <Stack direction="row" sx={{ gap: 1 }}>
        <ColorModeIconDropdown />
      </Stack>
    </Stack>
  );
}

import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridCellModesModel,
  GridCellModes,
} from "@mui/x-data-grid";
import styled from "@emotion/styled";
import "./style.css";
import { enqueueSnackbar } from "notistack";
import { BASEURL } from "../Utilities/properties";
import { colorSchemes } from "../dashboard/theme/themePrimitives";

interface District {
  id: number;
  name: string;
  registeredElectors: number;
  validVotesPercentage: number;
  NoOfSeats: number;
}

const ElectionCalculator: React.FC = () => {
  const [registeredElectors, setRegisteredElectors] = useState("");
  const [polledPercentage, setpolledPercentage] = useState("");
  const [validPercentage, setValidPercentage] = useState("");
  const [totalPolled, setTotalPolled] = useState(0);
  const [validVotes, setvalidVotes] = useState(0);
  const [unallocatedVotes, setUnallocatedVotes] = useState(0);
  const [seats, setSeats] = useState("");
  const [districts, setDistricts] = useState<District[]>();
  const [selectedDistrict, setSelectedDistrict] = useState("");

  const [rows, setRows] = useState([
    { id: 1, party: "A", votes: "", seats: 0 },
    { id: 2, party: "B", votes: "", seats: 0 },
    { id: 3, party: "C", votes: "", seats: 0 },
    { id: 4, party: "D", votes: "", seats: 0 },
    { id: 5, party: "E", votes: "", seats: 0 },
    { id: 6, party: "F", votes: "", seats: 0 },
    { id: 7, party: "G", votes: "", seats: 0 },
    { id: 8, party: "H", votes: "", seats: 0 },
    { id: 9, party: "Others", votes: unallocatedVotes, seats: 0 },
  ]);

  const handleProcessRowUpdate = (newRow: any, oldRow: any) => {
    if (newRow.party === "Others") {
      return oldRow;
    }

    const newVotes = newRow.votes ? Number(newRow.votes) : 0;
    const allocatedVotes = rows.reduce((acc, row) => {
      return (
        acc +
        (row.party !== "Others" && row.id !== newRow.id && row.votes
          ? Number(row.votes)
          : 0)
      );
    }, 0);

    const availableUnallocatedVotes = validVotes - allocatedVotes;

    if (newVotes > availableUnallocatedVotes) {
      enqueueSnackbar(
        `Cannot allocate more than ${availableUnallocatedVotes} Others.`,
        { variant: "error" }
      );
      return oldRow;
    }

    const updatedRow = { ...newRow };

    setRows((prevRows) =>
      prevRows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );

    const newAllocatedVotes = allocatedVotes + newVotes;
    const newUnallocatedVotes = validVotes - newAllocatedVotes;
    setUnallocatedVotes(newUnallocatedVotes > 0 ? newUnallocatedVotes : 0);

    setRows((prevRows) =>
      prevRows.map((row) =>
        row.party === "Others"
          ? { ...row, votes: newUnallocatedVotes > 0 ? newUnallocatedVotes : 0 }
          : row
      )
    );

    return updatedRow;
  };

  const handleCalculate = async () => {
    if (parseInt(seats) <= 0) {
      enqueueSnackbar("No of seats must be required", {
        variant: "error",
      });
      return;
    }

    const votesData = rows
      .filter(
        (row) =>
          row.party !== "Others" && row.votes && Number(row.votes) > 0
      )
      .map((row) => ({ party: row.party, votes: Number(row.votes) }));

    if (votesData.length <= 0) {
      enqueueSnackbar(
        "Please enter votes for at least one party before calculating.",
        {
          variant: "error",
        }
      );
      return;
    }

    const requestBody = {
      seats: seats,
      parties: votesData,
      validVotes: validVotes,
      districtId: selectedDistrict,
    };
    // console.log(requestBody);

    try {
      const response = await fetch(`${BASEURL}/calculate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const result = await response.json();

        const updatedRows = rows.map((row) => {
          const seatData = result.find((r: any) => r.party === row.party);
          return {
            ...row,
            seats: seatData ? seatData.seats : 0,
          };
        });

        setRows(updatedRows);
        enqueueSnackbar("Seats calculated successfully!", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Failed to calculate seats. Try again.", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error during calculation:", error);
      enqueueSnackbar("An error occurred while calculating seats.", {
        variant: "error",
      });
    }
  };

  // useEffect(() => {
  //   if (registeredElectors > 0 && polledPercentage) {
  //     const percentageValue = parseFloat(polledPercentage) / 100;
  //     const calculatedTotalPolled = Math.round(
  //       registeredElectors * percentageValue
  //     );
  //     setTotalPolled(calculatedTotalPolled);
  //   } else {
  //     setTotalPolled(0);
  //   }
  // }, [registeredElectors, polledPercentage]);

  useEffect(() => {
    if (parseInt(registeredElectors) > 0 && validPercentage) {
      const percentageValue = parseFloat(validPercentage) / 100;
      const calculatedTotalValidVotes = Math.round(
        parseInt(registeredElectors) * percentageValue
      );
      setvalidVotes(calculatedTotalValidVotes);

      setRows((prevRows) =>
        prevRows.map((row) =>
          row.party !== "Others"
            ? { ...row, votes: "", seats: 0 }
            : { ...row, votes: calculatedTotalValidVotes, seats: row.seats }
        )
      );

      setUnallocatedVotes(calculatedTotalValidVotes);
    } else {
      setvalidVotes(0);
    }
  }, [registeredElectors, validPercentage]);
  const [previousValues, setPreviousValues] = useState({
    registeredElectors: "",
    polledPercentage: "",
    validPercentage: "",
  });

  // useEffect(() => {
  //   console.log(polledPercentage);
  //   console.log("pre", previousValues.polledPercentage);

  //   const allocatedVotes = rows.reduce((acc, row) => {
  //     return row.party !== "Others" && row.votes
  //       ? acc + Number(row.votes)
  //       : acc;
  //   }, 0);

  //   if (allocatedVotes > validVotes) {
  //     enqueueSnackbar(
  //       "Total obtained votes exceed valid votes. Reverting changes.",
  //       {
  //         variant: "error",
  //       }
  //     );
  //     setRegisteredElectors(previousValues.registeredElectors);
  //     setpolledPercentage(previousValues.polledPercentage);
  //     setValidPercentage(previousValues.validPercentage);
  //   } else {
  //   }
  // }, [registeredElectors, polledPercentage, validPercentage, validVotes, rows]);

  // const handlePolledPercentageChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setPreviousValues({
  //     ...previousValues,
  //     polledPercentage: polledPercentage,
  //   });

  //   const value = event.target.value;
  //   if (isNaN(Number(value)) || Number(value) < 0 || Number(value) > 100) {
  //     enqueueSnackbar("Percentage must be between 0 and 100.", {
  //       variant: "error",
  //     });
  //   } else {
  //     setpolledPercentage(value);
  //   }
  // };

  const handleValidPercentageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPreviousValues({ ...previousValues, validPercentage: validPercentage });

    const value = event.target.value;
    if (isNaN(Number(value)) || Number(value) < 0 || Number(value) > 100) {
      enqueueSnackbar("Percentage must be between 0 and 100.", {
        variant: "error",
      });
    } else {
      setValidPercentage(value);
    }
  };

  // const handleRegisteredElectorsChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setPreviousValues({
  //     ...previousValues,
  //     registeredElectors: registeredElectors,
  //   });
  //   const value = event.target.value;
  //   if ( parseInt(value) >= 0) {
  //     setRegisteredElectors(value);
  //   }
  // };

  const handleRegisteredElectorsChange = (event: any) => {
    const inputValue = event.target.value.replace(/,/g, "");
    const numericValue = parseInt(inputValue, 10);

    if (!isNaN(numericValue) || inputValue === "") {
      setRegisteredElectors(inputValue);
    }
  };

  const formatNumberWithCommas = (num: any) => {
    return num ? parseInt(num, 10).toLocaleString() : "";
  };

  const columns: GridColDef[] = [
    {
      field: "party",
      headerName: "Party",
      flex: 1,
      align: "center",
      headerAlign: "center",
      cellClassName: (params: GridCellParams<any>) => {
        return params.row.party !== "Others"
          ? "customPartyCell"
          : "OtherVotesPartyCell";
      },
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "votes",
      headerName: "Obtained Votes",
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      editable: true,
      cellClassName: (params: GridCellParams<any>) => {
        return params.row.party !== "Others"
          ? "customVotesCell"
          : "customOtherVotesCell";
      },
      disableColumnMenu: true,
      sortable: false,
    },

    {
      field: "seats",
      headerName: "Seats",
      flex: 1,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  const DetailFormText = styled(Typography)({
    fontFamily: 'Outfit',
    fontSize: '16px',
    fontWeight: '400',
    textAlign: 'left',
    margin: '0px',
    color: 'text.Secondary',
});

  const DetailValueText = styled(Typography)`
    font-family: Outfit;
    font-weight: 400;
    text-align: left;
    margin: 0px;
  `;

  const StyledButton = styled(Button)({
    borderRadius: "30px",
    width: "120px",
    height: "50px",
    textAlign: "center",
    textTransform: "none",
    fontSize: "14px",
    fontFamily:'Outfit',
  });

  const [cellModesModel, setCellModesModel] =
    React.useState<GridCellModesModel>({});

  const handleCellClick = React.useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) {
        return;
      }

      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    []
  );

  const handleCellModesModelChange = React.useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    },
    []
  );

  const handleDistrictChange = async (event: any) => {
    const district = event.target.value;
    setSelectedDistrict(district);

    try {
      const response = await fetch(`${BASEURL}/districts/${district}`);
      const data = await response.json();
      // console.log("data: ", data);
      setSeats(data.seats);
      setValidPercentage(data.validPercentage);
      setRegisteredElectors(data.registeredElectors);
    } catch (error) {
      console.error("Error fetching district details:", error);
      enqueueSnackbar("Failed to fetch district details.", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await fetch(`${BASEURL}/districts`);
        const data = await response.json();
        // console.log(data);
        setDistricts(data);
      } catch (error) {
        console.error("Error fetching districts:", error);
        enqueueSnackbar("Failed to fetch districts.", { variant: "error" });
      }
    };

    fetchDistricts();
  }, []);
  const validPercentageInputRef = useRef<HTMLButtonElement | null>(null);
  const seatsInputRef = useRef<HTMLButtonElement | null>(null);


  return (
    <Box
      sx={{
        border: "2px solid #F0F5FA",
        borderRadius: "15px",
      }}
    >
      <Box sx={{ width: "100%", margin: "auto", padding: "20px 0px 0px 40px" }}>
        <Grid container  alignItems={"center"}>
          <Grid item sm={6} xs={11}>
            <FormControl
              fullWidth
              sx={{ marginBottom: { sm: "16px", xs: "0px" } }}
            >
              <InputLabel
                id="district-select-label"
                style={{ fontFamily: "Outfit" }}
              >
                Electoral District
              </InputLabel>
              <Select
                labelId="district-select-label"
                label="Electoral District"
                value={selectedDistrict}
                onChange={handleDistrictChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
                sx={{
                  borderRadius: "8px",
                  "& .MuiSelect-select": {
                    padding: "14px 14px",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "Outfit",
                    fontSize: "16px",
                    fontWeight: "400",
                  },
                }}
              >
                {districts ? (
                  districts.map((district) => (
                    <MenuItem
                      key={district.id}
                      value={district.id}
                      sx={{ fontWeight: "400", fontFamily: "Outfit" }}
                    >
                      {district.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No districts available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "100%", margin: "auto", padding: "0px 0px 20px 40px", paddingTop:{xs:'20px', sm:'0px'} }}>
        <Grid container spacing={0} alignItems={"center"}>
          <Grid item sm={6} xs={12}>
            <Grid container  alignItems={'center'} sx={{minHeight:'60px', paddingBottom:{xs:'10px', sm:'0px'}}} >
              <Grid item sm={6} xs={selectedDistrict? 7.5 : 11}>

                <DetailFormText>Registered Electors:</DetailFormText>
              </Grid>
              <Grid item sm={6} xs={selectedDistrict? 2.5 : 11}>
                <TextField
                  fullWidth
                  name="registeredElectors"
                  variant="outlined"
                  type="text"
                  value={formatNumberWithCommas(registeredElectors)}
                  onChange={handleRegisteredElectorsChange}
                  InputProps={{
                    readOnly:selectedDistrict? true:false,
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      seatsInputRef.current?.focus();
                    }}}
                  sx={{
                    input: {
                      padding: "5px",
                      fontFamily: "Outfit",
                      fontWeight: "400",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        border: selectedDistrict? "none" : '',
                      },
                    },
                    borderRadius: "8px",
                    // marginBottom: { sm: "16px", xs: "0px" },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={12} >
            <Grid container alignItems={"center"} sx={{minHeight:'60px', paddingBottom:{xs:'10px', sm:'0px'}}}>
              <Grid item sm={2}></Grid>
              <Grid item sm={3.7} xs={selectedDistrict? 7.5 : 11}>
                <DetailFormText>No of seats:</DetailFormText>
              </Grid>
              <Grid item sm={4} xs={selectedDistrict? 2.5 : 11} alignItems={'center'}>
                <TextField
                  fullWidth
                  name="seats"
                  variant="outlined"
                  type="number"
                  value={seats}
                  onChange={(e) => setSeats(e.target.value)}
                  inputRef={seatsInputRef}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      validPercentageInputRef.current?.focus();
                    }}}
                  InputProps={{
                    readOnly:selectedDistrict? true:false,
                  }}
                  sx={{
                    input: {
                      padding: "5px",
                      fontFamily: "Outfit",
                      fontWeight: "400",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        border: selectedDistrict? "none" : '',
                      },
                    },
                    borderRadius: "8px",
                    // marginBottom: { sm: "16px", xs: "0px" },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item xs={6}>
            <Grid container alignItems={"center"}>
              <Grid item xs={5.5}>
                <DetailFormText>Polled Percentage</DetailFormText>
              </Grid>
              <Grid item xs={6.5}>
                <TextField
                  fullWidth
                  name="polledPercentage"
                  variant="outlined"
                  type="number"
                  value={polledPercentage}
                  onChange={handlePolledPercentageChange}
                  sx={{
                    input: { padding: "4px" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        borderRadius: "8px",
                      },
                    },
                    backgroundColor: "#CEDBF0",
                    borderRadius: "8px",
                    marginBottom: "16px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container alignItems={"center"}>
              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
                <DetailFormText>Total polled:</DetailFormText>
              </Grid>
              <Grid item xs={6}>
                <DetailFormText>
                  {polledPercentage ? totalPolled : "---"}
                </DetailFormText>
              </Grid>
            </Grid>
          </Grid> */}

          <Grid item sm={6} xs={12}>
            <Grid container alignItems={"center"} sx={{minHeight:'60px', paddingBottom:{xs:'10px', sm:'0px'}}}>
              <Grid item sm={6} xs={12}>
                <DetailFormText>Expected Valid Voting %:</DetailFormText>
              </Grid>
              <Grid item sm={6} xs={11}>
                <TextField
                  fullWidth
                  name="validPercentage"
                  variant="outlined"
                  type="number"
                  value={validPercentage}
                  inputRef={validPercentageInputRef}
                  onChange={handleValidPercentageChange}
                  sx={{
                    input: {
                      padding: "5px",
                      fontFamily: "Outfit",
                      fontWeight: "400",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                      },
                    },
                    borderRadius: "8px",
                    // marginBottom: { sm: "16px", xs: "0px" },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={10}>
            <Grid container alignItems={"center"}>
              <Grid item sm={2}></Grid>
              <Grid item sm={5.7} xs={9}>
                <DetailFormText>Expected Valid Votes:</DetailFormText>
              </Grid>
              <Grid item sm={4.3} xs={3}>
                <DetailValueText>
                  {validPercentage ? validVotes.toLocaleString() : "---"}
                </DetailValueText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ border: " 0.5px solid #F0F5FA" }} />
      <Grid container justifyContent={"center"}>
        <Grid item xs={12}>
          <Box
            sx={{
              margin: "auto",
              marginTop: "0px",
              width: "100%",
            }}
          >
            <DataGrid
              autoHeight
              rows={rows}
              columns={columns}
              cellModesModel={cellModesModel}
              onCellModesModelChange={handleCellModesModelChange}
              onCellClick={handleCellClick}
              processRowUpdate={handleProcessRowUpdate}
              hideFooter
              sx={{
                fontFamily: "Outfit",
                "& .MuiDataGrid-cell": {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "400",
                  borderRadius: "0px",
                },
                "& .MuiDataGrid-cell[data-field='party'].MuiDataGrid-cell": {
                  fontWeight: "400",
                  border: "none",
                },
                "& .MuiDataGrid-cell[data-field='seats'].MuiDataGrid-cell": {
                  border: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  borderBottom: "none",
                  backgroundColor: "transparent",
                  fontSize:'17px'
                },

                "& .MuiDataGrid-row": {
                  borderBottom: "none",
                  margin: "0px 0px 0px 0px",
                  "&:hover": {
                    backgroundColor: "inherit",
                  },
                },
                "&.MuiDataGrid-root": {
                  border: "none",
                  borderRadius: "0px",
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ border: " 0.5px solid #F0F5FA" }} />
          <Grid container justifyContent={{ sm: "flex-start", xs: "center" }}>
            <Grid item>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={handleCalculate}
                sx={{
                  margin: { sm: "13px 0px 13px 50px", xs: "13px 0px 13px 0px" },
                }}
              >
                Calculate
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ElectionCalculator;
